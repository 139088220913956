import React from 'react'
import { Card, Col, Button } from 'react-bootstrap'
import './experience.css'

const Experience = () => {
    return (
        <div className='experience'>
            <div>
                <h4 className='  mb-4 custom-color'>Experiencia</h4>
                <Col>
                    <Card className='card-custom card-experience h-100 w-100'>
                        <Card.Title className=' custom-color mb-2'>Conecta S.A</Card.Title>
                        <Card.Subtitle>
                            <strong>Practica Profesional</strong>
                        </Card.Subtitle>
                        <Card.Text className=' mt-3 h-100'>
                            Práctica profesional de aproximadamente 4 meses, durante la cual establecí las bases para la creación de una plataforma de variables eléctricas. Desarrollé dos servicios: un sistema de información en tiempo real y un sistema de lectura de protecciones. Utilicé tecnologías como Next.js, Python con el framework FastAPI, y una base de datos NoSQL, específicamente MongoDB.
                        </Card.Text>
                        <Card.Body className='p-0'>
                            <h6 className='m-0'>Tecnologias</h6>
                            <div className=' display-1 m-0 p-0'>
                                <img className='m-lg-3 m-1' src={"/icons/python (1).svg"} alt='logo'></img>
                                <img className='m-lg-3 m-1' src={"/icons/fastapi-1.svg"} alt='logo'></img>
                                <img className='m-lg-3 m-1 rounded-5 bg-light' src={"/icons/next-js.svg"} alt='logo'></img>
                                <img className='m-lg-3 m-1' src={"/icons/mongodb-original.svg"} alt='logo'></img>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='mt-3'>
                    <Card className='card-custom card-experience h-100 w-100'>
                        <Card.Title className=' custom-color mb-2'>Nutrifoods</Card.Title>
                        <Card.Subtitle>
                            <strong>Proyecto Personal</strong>
                        </Card.Subtitle>
                        <Card.Text className=' mt-3 h-100'>
                            Actualmente estoy trabajando en un proyecto con un equipo compuesto por mis compañeros, enfocado en el desarrollo de una plataforma nutricional. Esta plataforma cubre la gestión de pacientes y ofrece un valor agregado al generar planes alimenticios de manera automática, mediante la aplicación de algoritmos evolutivos para encontrar las soluciones óptimas.
                        </Card.Text>
                        <Card.Body className='p-0'>
                            <h6 className='m-0'>Tecnologias</h6>
                            <div className=' display-1 m-0 p-0 d-flex justify-content-between align-items-center'>
                                <div>
                                    <img className='m-lg-3 m-1' src={"/icons/c-sharp-c.svg"} alt='logo'></img>
                                    <img className='m-lg-3 m-1' src={"/icons/blazor.svg"} alt='logo'></img>
                                    <img className='m-lg-3 m-1' src={"/icons/postgresql.svg"} alt='logo'></img>
                                </div>
                                
                                <Button href="https://github.com/EduardoPalma/NutriFoods" variant="dark" className="d-flex align-items-center ml-auto">
                                    Repositorio
                                    <svg className='icono' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github m-2" viewBox="0 0 16 16">
                                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                                    </svg>
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </div>
    )
}

export default Experience