import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Card, CardImg, Col, Row } from 'react-bootstrap';
import './card.css';
import { ButtonProyect } from './ButtonProyect';
import { Tecnologies } from './Tecnologies';
import { saveAs } from 'file-saver';


export const Intro = () => {
    const descargarArchivo = () => {
        const url = '/files/CV-EAPP.pdf';
        const nombrePersonalizado = 'Eduardo-Palma-CV';
        saveAs(url, nombrePersonalizado);
    };

    const copyEmail = () => {
        navigator.clipboard.writeText("eduardoarmando.palma@gmail.com")
            .then(() => {
                window.alert('¡Correo copiado!')
            })
    };

    return (
        <Col md={8}>
            <Row className='heigh-row'>
                <Card className='card-custom delay-1 justify-content-center align-items-center'>
                    <Row className=''>
                        <Col md={8}>
                            <h4 className='custom-color'>Bienvenidos,</h4>
                            <p className='font'>Holaaa, soy <strong>Eduardo Palma</strong>, soy amante de las tecnologias, y me apasiona el desarrollo de aplicaciones web y la inteligencia artificial. </p>
                            <Button className='button-cs mt-5' variant="outline-success" onClick={descargarArchivo}>
                                <span className='margin-span'>Curriculum</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                </svg>
                            </Button>
                        </Col>
                        <Col md={4}>
                            <div className='main-image'>
                                <CardImg className='img-especial' src="/image/foto.jpg"></CardImg>
                            </div>

                        </Col>
                    </Row>
                </Card>
            </Row>
            <Row className='heigh-row pt-2'>
                <Col md={4} className='p-0'>
                    <Card className='card-custom delay-2 h-100'>
                        <div>
                            <h6 className='custom-color text-proyect'>Proyectos Principales </h6>
                            <div className='proyect-link m-0'>
                                <p className='font-name-proyect'>DataFoods</p>
                                <ButtonProyect link="datafoods" />
                            </div>
                            <div className='proyect-link m-0'>
                                <p className='font-name-proyect'>Análisis de Noticias</p>
                                <ButtonProyect link="noticias" />
                            </div>

                        </div>
                        <div>
                            <h6 className='custom-color text-proyect'>Otros proyectos</h6>
                            <div className='proyect-link'>
                                <p className='font-name-proyect'>Proyectos con IA </p>
                                <ButtonProyect link="ia" />
                            </div>
                            <div className='proyect-link'>
                                <p className='font-name-proyect'>Aprendizaje</p>
                                <ButtonProyect link="otros" />
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col md={8}>
                    <Row className='row-pd'>
                        <Card className='card-custom delay-3'>
                            <div>
                                <h6 className='custom-color custom-h5'> Trabajemos juntos</h6>
                                <span className='font'><em>eduardoarmando.palma@gmail.com</em></span>
                                <Button className='button-copy button-cs' variant='outline-secondary' onClick={copyEmail}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                    </svg>
                                </Button>
                            </div>
                            <div>
                                <h6 className='custom-color custom-h5'>Redes</h6>
                                <Button className='link link-especial button-cs' href='https://github.com/EduardoPalma' variant="outline-secondary">
                                    <svg className='icono' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                                    </svg>
                                </Button>
                                <Button className='link link-especial button-cs' variant='outline-secondary' href='https://linkedin.com/in/eduardo-palma-pizarro-7924022aa'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                                    </svg>
                                </Button>
                            </div>
                        </Card>
                    </Row>
                    <Row className='row-pd heigh-row'>
                        <Col className='pt-2 p-0 pb-1 d-flex justify-content-between'>
                            <Card className='card-custom delay-4 p-3' style={{ width: '50%', marginRight: '10px' }}>
                                <h6 className='custom-color'> Habilidades Tecnicas</h6>
                                <Tecnologies />
                            </Card>
                            <Card className='card-custom delay-5 p-3' style={{ width: '50%' }}>
                                <h6 className='custom-color'> Habilidades Blandas</h6>
                                <li className='list custom-text'>
                                    <strong>Comunicacion Efectiva</strong>
                                </li>
                                <li className='list custom-text'>
                                    <strong>Trabajo en equipo</strong>
                                </li>
                                <li className='list custom-text'>
                                    <strong>Capacidad de adaptacion y Aprendizaje</strong>
                                </li>
                                <li className='list custom-text'>
                                    <strong>Resolucion de problemas</strong>
                                </li>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}
