import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

export const AboutMe = () => {
    return (
        <Col md={4}>
            <Row className='row-pd'>
                <Card className='card-custom delay-6'>
                    <h4 className='custom-color custom-h4'>
                        Sobre mi
                    </h4>
                    <p className='font font-especial font-name-proyect'>
                        <span>Titulado de la carrera de Ingeniería en Computación de la Universidad de La Serena.
                            Durante mi formación, adquirí conocimientos en diversos ámbitos, desde los fundamentos de estructuras de datos, ingeniería de software,
                            redes, bases de datos y Inteligencia Artificial.</span>

                        <p>Mis tecnologias mas utilizadas</p>
                        <ul className=' '>
                            <p className='custom-color custom-h4'> Python y C#</p>
                        </ul>
                        <p>En mi último año, exploré tecnologías avanzadas en el campo de la inteligencia artificial, participando en proyectos enfocados en el NLP y DeepLearning.</p>
                        <p>Actualmente me estoy profundizando en tecnologias del mundo Web y Cloud como tambien en el area de la Programacion Evolutiva</p>
                    </p>
                </Card>
            </Row>
            <Row className='row-pd'>
                <Card className='mt-2 card-custom card-other-end delay-7'>
                    <h6>Otras tecnologias</h6>
                    <div className='Tegno h-100 p-3'>
                        <div className='w-50'>
                            <p>Librerias</p>
                            <img className='m-1' src={"/icons/fastapi-1.svg"} alt='tecno'>
                            </img>
                            <img className='m-1' src={"/icons/tensorflow.svg"} alt='tecno'>
                            </img>
                            <img className='m-1 bg-light rounded-5' src={"/icons/next-js.svg"} alt='tecno'>
                            </img>
                        </div>
                        <div className='w-50'>
                            <p>Bases de datos</p>
                            <img className='m-1' src={"/icons/elasticsearch.svg"} alt='tecno'>
                            </img>
                            <img className='m-1' src={"/icons/mongodb-original.svg"} alt='tecno'>
                            </img>
                            <img className='m-1' src={"/icons/postgresql.svg"} alt='tecno'>
                            </img>
                        </div>
                    </div>
                </Card>
            </Row>
        </Col>
    )
}
