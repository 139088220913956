import React from 'react'

export const ProyectInfo = ({tecnologies}) => {
  return (
    <div className='contenedor-svg'>
      {tecnologies.map((objeto, index) => (
        <div className='tecno'>
          <img src={objeto.logo} alt="logo" key={index}></img>
          <p className='name-tecno'>{objeto.name}</p>
        </div>
        ))}
    </div>
  )
}
