import React from 'react'
import './tecnologies.css';


export const Tecnologies = () => {
  return (
    <div className="contenedor-svg">
        <img src='/icons/python (1).svg' alt='tecno'></img>
        <img src='/icons/c-sharp-c.svg' alt='tecno'></img>
        <img src='/icons/react-original-wordmark.svg' alt='tecno'></img>

    
    </div>
  )
}
