import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Intro } from './intro';
import { Container, Row } from 'react-bootstrap';
import { AboutMe } from './AboutMe';

export const Cards = () => {
  return (
    <Container className='total total-main'>
        <Row>
            <Intro/>
            <AboutMe/>
        </Row>
    </Container>
  )
}
