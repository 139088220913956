import { useState } from 'react';
import './App.css';
import { Proyect } from './components/Proyect';
import { Cards } from './components/card';
import { useEffect } from 'react';
import Experience from './components/experience';

function App() {
  const [tecnologias, setTecnologias] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetch('/files/data.json')
          .then((response) => response.json())
          .then((tecnologias) => setTecnologias(tecnologias));
        
      } catch (error) {
        console.error('Error al cargar los datos JSON', error);
      }
    };

    fetchData();
  }, []);
  
  return (
    <div className="App">
      <header className="App-header">
        <Cards />
        <Experience />
        {tecnologias.map((data) => (
          <Proyect 
            titulo={data.name}
            proposito={data.proposito}
            descripcion={data.descripcion}
            nameImage={data.nameImagen}
            name={data.nameID}
            tecno={data.tecnologias}
            repoUrl={data.repoUrl} />
        ))}
      </header>
    </div>
  );
}

export default App;
